import Home from './Pages/Home/Home'

function App() {
  return (
    <div> 
      <Home/>
    </div>
  );
}

export default App;
